<template>
    <div class="clearfix hide-print" style="padding:10px 30px;background-color: #f5f5f5;border: 1px solid #e7e7e7;border-bottom:0px;">
        <div class="btn-toolbar pull-right">
            <div class="btn-group">
                <button id="time-dropdown-btn" type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="glyphicon glyphicon-calendar" aria-hidden="true"></span> <span id="time-dropdown-btn-text">{{filter.year}}</span> <span class="caret"></span>
                </button>
                <ul class="dropdown-menu pull-right" id="time-dropdown">
                    <li v-for="year in arrayYear" :key="year"><input type="hidden" :value="year"/><a @click.prevent="filterYearChanged(year)" href = "">{{year}}</a></li>
                </ul>
            </div>
            <div class="btn-group">
                <button id="user-dropdown-btn" type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="glyphicon glyphicon-user" aria-hidden="true"></span> <span id="user-dropdown-btn-text">{{StringsHelper.getCreatorSimple(filter.creator)}}</span> <span class="caret"></span>
                </button>
                <ul class="dropdown-menu pull-right" id="user-dropdown" >
                    <li><input type="hidden" :value="null" /><a @click.prevent="filterCreatorChanged()" href = "">{{StringsHelper.getCreatorSimple()}}</a></li>
                    <li v-for = "creator in creatorsOptions" :key = "creator.value"><input type="hidden" name = "creatorsOptions" :value="creator.value" /><a @click.prevent="filterCreatorChanged(creator.value)" href = "">{{creator.name}}</a></li>
                </ul>
            </div>
            <a class="btn btn-default btn-sm hide-print" href="javascript:;" onclick="window.print();">
                <span class="glyphicon glyphicon-print" aria-hidden="true"></span> Stampa
            </a>
            <a class="btn btn-default btn-sm hide-print" href="javascript:;" @click.prevent.stop="exportAnalytics">
                <span class="glyphicon glyphicon-save-file" aria-hidden="true"></span> Esporta
            </a>
        </div>
    </div>
</template>

<script>
	import baseMixin from '@/common/baseMixin'
    import moment from 'moment'

	export default {
		
		name: "AnalyticsFilters",
		mixins: [baseMixin],
		props: {
            defaultYear: {
                type: Number,
                default: moment().year()
            },
            defaultCreator: {
                type: String,
                default: null
            }
		},
		mounted () {
            this.creatorsOptions = this.Keys.CREATORS_OPTIONS();
            this.filter = {
                creator: this.defaultCreator,
                year: this.defaultYear
            }
		},
		data: () => {
			return {
                creatorsOptions: [],
                filter: {}
			}
		},
        computed: {
            arrayYear: function () {
                let startYear = 2020;
                let arrayYear = [];

                for (let i = startYear; i <= this.defaultYear; i++) {
                    arrayYear.push(i)
                }
                return arrayYear;
            }
        },
		methods: {
            filterYearChanged(year=null) {
                this.filter.year = year;
                this.$emit('filter-changed', this.filter)
            },
            filterCreatorChanged(creator=null) {
                this.filter.creator = creator;
                this.$emit('filter-changed', this.filter)
            },

            async exportAnalytics() {
                this.$emit('export-file', this.filter);
            },
		}
	}
</script>
