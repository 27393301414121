import {StringsHelper} from "./stringsHelper";
import {COUNTRIES} from "./countries";

export class Analytics {

    static MAX_COUNTRY_CHART = 7;


    static getDataVisitorMonth(visitor_month_label, visitor_month_data) {
        let visitor_month_data_final = [0,0,0,0,0,0,0,0,0,0,0,0,0];

        let counterMonth = 0;

        for (let i = 1; i <= 12; i++) {
            if (visitor_month_label.includes(i)) {
                visitor_month_data_final[i-1] = visitor_month_data[counterMonth];
                counterMonth++
            }
        }
        return visitor_month_data_final
    }


    static getDataRevenuesMonth(revenues_month_label, revenues_month_data) {
        let revenues_month_data_final = [0,0,0,0,0,0,0,0,0,0,0,0,0];

        let counterMonth = 0;

        for (let i = 1; i <= 12; i++) {
            if (revenues_month_label.includes(i)) {
                revenues_month_data_final[i-1] = revenues_month_data[counterMonth];
                counterMonth++
            }
        }
        return revenues_month_data_final
    }


    static getLabelDataVisitorCountry(arrayCountry) {
        let sumOther = 0;
        let country_visitors_label_final = [];
        let country_visitors_data_final = [];

        for (let i = 0; i < arrayCountry.length; i++) {
            if (i < Analytics.MAX_COUNTRY_CHART) {
                country_visitors_label_final.push(Analytics.getStringCountryFromISO(arrayCountry[i].country));
                country_visitors_data_final.push(arrayCountry[i].people);
            } else {
                sumOther += arrayCountry[i].people
            }

        }

        if (sumOther != 0) {
            country_visitors_label_final.push("Altro");
            country_visitors_data_final.push(sumOther);
        }

        return  {
            data: country_visitors_data_final,
            label: country_visitors_label_final
        }
    }

    static getLabelDataRevenuesCountry(arrayCountry) {
        let sumOther = 0;
        let country_revenues_label_final = [];
        let country_revenues_data_final = [];

        for (let i = 0; i < arrayCountry.length; i++) {
            if (i < Analytics.MAX_COUNTRY_CHART) {
                country_revenues_label_final.push(Analytics.getStringCountryFromISO(arrayCountry[i].country));
                country_revenues_data_final.push(parseFloat(arrayCountry[i].price_sum).toFixed(2));
            } else {
                sumOther += parseFloat(arrayCountry[i].price_sum)
            }

        }

        if (sumOther != 0) {
            country_revenues_label_final.push("Altro");
            country_revenues_data_final.push(sumOther.toFixed(2));
        }


        return  {
            data: country_revenues_data_final,
            label: country_revenues_label_final
        }
    }

    static getLabelDataRevenuesProduct(arrayProduct) {
        let product_revenues_label_final = [];
        let product_revenues_data_final = [];

        for (let i = 0; i < arrayProduct.length; i++) {
            product_revenues_label_final.push(StringsHelper.getProduct(arrayProduct[i].product));
            product_revenues_data_final.push(arrayProduct[i].price_sum.toFixed(2));
        }

        return  {
            data: product_revenues_data_final,
            label: product_revenues_label_final
        }
    }

    static getLabelDataVisitorProduct(arrayProduct) {
        let product_visitors_label_final = [];
        let product_visitors_data_final = [];

        for (let i = 0; i < arrayProduct.length; i++) {
            product_visitors_label_final.push(StringsHelper.getProduct(arrayProduct[i].product));
            product_visitors_data_final.push(arrayProduct[i].people);
        }

        return  {
            data: product_visitors_data_final,
            label: product_visitors_label_final
        }
    }

    static getStringCountryFromISO(isoCountry) {
        return COUNTRIES[isoCountry];
    }

    static getNumber(number) {
        if (number) {
            return parseInt(number)
        }

        return 0
    }
}
