<template>
    <ContainerWithMenu :loading="loading">
        <AnalyticsFilters @filter-changed="filterChanged" @export-file="exportFile"></AnalyticsFilters>
        <div class="page-content">
            <section class="sheet padding-10mm">
                <h2 class="margin-bottom-40">
                    Statistiche {{currentYear}} - Presenze
                    <small class="pull-right" id="today_date">{{todayDate}}</small>
                </h2>
                <div class="row margin-bottom-40">
                    <div class="col-xs-12 margin-bottom-40">
                        <p>Visitatori unici mese per mese</p>
                        <canvas id="visitorsChartMonth" height="60"></canvas>
                    </div>
                    <div class="col-xs-4">
                        <div class="margin-bottom-40">
                            <p>Visitatori totali</p>
                            <h2 class="h2 margin-0" id="visitorsTotalChartTitle">{{visitorsTotalChartTitle}}</h2>
                            <canvas id="visitorsTotalChart" height="40"></canvas>
                        </div>
                        <div class="margin-bottom-40">
                            <p>Visitatori residenti</p>
                            <h2 class="h2 margin-0" id="visitorsTotalResidentChartTitle">{{visitorsTotalResidentChartTitle}}</h2>
                            <canvas id="visitorsTotalResidentChart" height="40"></canvas>
                        </div>
                        <div>
                            <p>Visitatori non residenti</p>
                            <h2 class="h2 margin-0" id="visitorsTotalNotResidentChartTitle">{{visitorsTotalNotResidentChartTitle}}</h2>
                            <canvas id="visitorsTotalNotResidentChart" height="40"></canvas>
                        </div>
                    </div>
                    <div class="col-xs-8">
                        <p>Visitatori per nazionalità</p>
                        <canvas id="visitorsChartCountry" height="180"></canvas>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <p>Presenze per sito</p>
                        <canvas id="visitorsChartProduct" height="100"></canvas>
                    </div>
                </div>
                <span class="page-number">1</span>
            </section>
            <section class="sheet padding-10mm">
                <h2 class="margin-bottom-40">
                    Statistiche {{currentYear}} - Ricavi
                    <small class="pull-right" id="today_date2">{{todayDate}}</small>
                </h2>
                <div class="row">
                    <div class="col-xs-12 margin-bottom-40">
                        <p>Ricavi mese per mese</p>
                        <canvas id="revenuesChartMonth" height="60"></canvas>
                    </div>
                    <div class="col-xs-4">
                        <div class="margin-bottom-40">
                            <p>Ricavi totali</p>
                            <h2 class="h2 margin-0" id="revenuesTotalChartTitle">{{revenuesTotalChartTitle}}</h2>
                            <canvas id="revenuesTotalChart" height="40"></canvas>
                        </div>
                    </div>
                    <div class="col-xs-8">
                        <p>Ricavi per nazionalità</p>
                        <canvas id="revenueChartCountry" height="180"></canvas>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <p>Ricavi per sito</p>
                        <canvas id="revenuesChartProduct" height="100"></canvas>
                    </div>
                </div>
                <span class="page-number">2</span>
            </section>
            <section class="sheet padding-10mm">
            <h2 class="margin-bottom-40">
              Statistiche {{currentYear}} - Statistiche per giorno
              <small class="pull-right" id="today_date3">{{todayDate}}</small>
            </h2>
            <div class="row">
              <div class="col-xs-12 margin-bottom-20">
                <p>Visitatori giornalieri</p>
                <canvas id="visitorsChartDaily" height="80"></canvas>
              </div>
            </div>
              <div class="row">
                <div class="col-xs-12 margin-bottom-20">
                  <p>Visitatori non residenti giornalieri </p>
                  <canvas id="notResidentVisitorsChartDaily" height="80"></canvas>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 margin-bottom-20">
                  <p>Visitatori residenti giornalieri </p>
                  <canvas id="residentVisitorsChartDaily" height="80"></canvas>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 margin-bottom-20">
                  <p>Ricavi giornalieri </p>
                  <canvas id="revenuesChartDaily" height="80"></canvas>
                </div>
              </div>

            <span class="page-number">3</span>
          </section>
        </div>
    </ContainerWithMenu>
</template>

<script>
    import moment from 'moment'

    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import {exportAnalytics, getAnalytics} from "../api";
    import baseMixin from "../common/baseMixin";
    import {Analytics} from "../common/analytics";
    import AnalyticsFilters from "../components/Analytics/AnalyticsFilters";
    import { Chart } from 'chart.js';
    import zoomPlugin from 'chartjs-plugin-zoom';

    export default {
        title: "Statistiche",
        name: "Analytics",
        mixins: [baseMixin],
        components: {ContainerWithMenu, AnalyticsFilters},
        created() {
            document.body.classList.add("A4");
        },
        beforeDestroy() {
            document.body.classList.remove("A4");
        },
        data: () => {
            return {
                loading: false,

                visitorsChartMonth: null,
                visitorsTotalChart: null,
                visitorsTotalResidentChart: null,
                visitorsTotalNotResidentChart: null,
                visitorsChartCountry: null,
                visitorsChartProduct: null,
                visitorsChartDaily: null,
                notResidentVisitorsChartDaily: null,
                residentVisitorsChartDaily: null,
                revenuesChartDaily: null,
                revenuesChart: null,
                revenuesTotalChart: null,
                revenueChartCountry: null,
                revenuesChartProduct : null,

                visitorsTotalChartTitle: 0,
                visitorsTotalResidentChartTitle: 0,
                visitorsTotalNotResidentChartTitle: 0,
                revenuesTotalChartTitle: 0,

                defaultYearFilter : moment().year(),
                defaultCreatorFilter: null,

                currentYear: null
            }
        },
        mounted() {
            this.loadChartData(this.defaultYearFilter, this.defaultCreatorFilter)
        },
        methods: {
            filterChanged(filter){
                this.loadChartData(filter.year, filter.creator);
            },
            async exportFile(filter){
                this.loading = true;
                try {
                    await exportAnalytics(filter.year, filter.creator);
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            async loadChartData(year, creator) {
                this.currentYear = year;

                this.loading = true;
                try {
                    let result = await getAnalytics(year, creator);
                    this.loading = false;

                    // Clearing chart
                    if(this.visitorsChartMonth && this.visitorsTotalChart && this.visitorsTotalResidentChart &&
                        this.visitorsTotalNotResidentChart && this.visitorsChartCountry && this.visitorsChartProduct &&
                        this.revenuesChart && this.revenuesTotalChart && this.revenueChartCountry &&
                        this.revenuesChartProduct && this.visitorsChartDaily && this.notResidentVisitorsChartDaily &&
                        this.residentVisitorsChartDaily && this.revenuesChartDaily) {

                        this.visitorsChartMonth.destroy();
                        this.visitorsTotalChart.destroy();
                        this.visitorsTotalResidentChart.destroy();
                        this.visitorsTotalNotResidentChart.destroy();
                        this.visitorsChartCountry.destroy();
                        this.visitorsChartProduct.destroy();
                        this.revenuesChart.destroy();
                        this.visitorsChartDaily.destroy();
                        this.notResidentVisitorsChartDaily.destroy();
                        this.residentVisitorsChartDaily.destroy();
                        this.revenuesChartDaily.destroy();
                        this.revenuesTotalChart.destroy();
                        this.revenueChartCountry.destroy();
                        this.revenuesChartProduct.destroy();
                    }

                    // grafico presenze mensili
                    let visitors_month_label = result.visitors.grouped_by_month.map(a => a.month);
                    let visitors_month_data = result.visitors.grouped_by_month.map(a => a.people);

                    let visitors_data_per_month = Analytics.getDataVisitorMonth(visitors_month_label, visitors_month_data);

                    let ctx1 = document.getElementById('visitorsChartMonth');
                    this.visitorsChartMonth = new Chart(ctx1, {
                        type: 'line',

                        data: {
                            labels: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
                            datasets: [{
                                label: 'Visitatori',
                                backgroundColor: 'rgba(79, 99, 196, 0.3)',
                                borderColor: 'rgb(78, 98, 196)',
                                borderWidth: '3',
                                pointBackgroundColor: 'rgba(79, 99, 196)',
                                pointRadius: '5',
                                pointBorderWidth: '0',
                                pointHoverBorderWidth: '0',
                                pointHoverRadius: '5',
                                pointHoverBackgroundColor: 'rgba(79, 99, 196)',
                                pointHoverBorderColor: 'rgba(79, 99, 196)',
                                lineTension: 0,
                                data: visitors_data_per_month
                            }]
                        },

                        // Configuration options go here
                        options: {
                            legend: {
                                display: false
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        display: false
                                    }
                                }]
                            }
                        }
                    });


                    // presenze totali
                    this.visitorsTotalChartTitle = Analytics.getNumber(result.visitors.total.people);


                    let ctx2 = document.getElementById('visitorsTotalChart');
                    this.visitorsTotalChart = new Chart(ctx2, {
                        // The type of chart we want to create
                        type: 'line',

                        // The data for our dataset
                        data: {
                            labels: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
                            datasets: [{
                                label: '€',
                                backgroundColor: 'rgba(79, 99, 196, 0.3)',
                                borderColor: 'rgb(78, 98, 196)',
                                borderWidth: '2',
                                lineTension: 0,
                                spanGaps: false,
                                data: visitors_data_per_month
                            }]
                        },

                        // Configuration options go here
                        options: {
                            legend: {
                                display: false
                            },
                            elements: {
                                point:{
                                    radius: 0
                                }
                            },
                            tooltips: {
                                enabled: false
                            },
                            scales: {
                                yAxes: [{
                                    display: false
                                }],
                                xAxes: [{
                                    display: false
                                }]
                            }
                        }
                    });


                    // Presenze totali residenti
                    this.visitorsTotalResidentChartTitle = Analytics.getNumber(result.visitors.total_resident.people);

                    let ctx3 = document.getElementById('visitorsTotalResidentChart');

                    let visitors_resident_month_label = result.visitors.resident_grouped_by_month.map(a => a.month);
                    let visitors_resident_month_data = result.visitors.resident_grouped_by_month.map(a => a.people);

                    this.visitorsTotalResidentChart = new Chart(ctx3, {
                        // The type of chart we want to create
                        type: 'line',

                        // The data for our dataset
                        data: {
                            labels: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
                            datasets: [{
                                label: '€',
                                backgroundColor: 'rgba(79, 99, 196, 0.3)',
                                borderColor: 'rgb(78, 98, 196)',
                                borderWidth: '2',
                                lineTension: 0,
                                spanGaps: false,
                                data: Analytics.getDataVisitorMonth(visitors_resident_month_label, visitors_resident_month_data)
                            }]
                        },

                        // Configuration options go here
                        options: {
                            legend: {
                                display: false
                            },
                            elements: {
                                point:{
                                    radius: 0
                                }
                            },
                            tooltips: {
                                enabled: false
                            },
                            scales: {
                                yAxes: [{
                                    display: false
                                }],
                                xAxes: [{
                                    display: false
                                }]
                            }
                        }
                    });

                    // grafico presenze totali non residenti
                    this.visitorsTotalNotResidentChartTitle = Analytics.getNumber(result.visitors.total_not_resident.people);
                    let visitors_not_resident_month_label = result.visitors.not_resident_grouped_by_month.map(a => a.month);
                    let visitors_not_resident_month_data = result.visitors.not_resident_grouped_by_month.map(a => a.people);

                    let ctx4 = document.getElementById('visitorsTotalNotResidentChart');
                    this.visitorsTotalNotResidentChart = new Chart(ctx4, {
                        // The type of chart we want to create
                        type: 'line',

                        // The data for our dataset
                        data: {
                            labels: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
                            datasets: [{
                                label: '€',
                                backgroundColor: 'rgba(79, 99, 196, 0.3)',
                                borderColor: 'rgb(78, 98, 196)',
                                borderWidth: '2',
                                lineTension: 0,
                                spanGaps: false,
                                data: Analytics.getDataVisitorMonth(visitors_not_resident_month_label, visitors_not_resident_month_data)
                            }]
                        },

                        // Configuration options go here
                        options: {
                            legend: {
                                display: false
                            },
                            elements: {
                                point:{
                                    radius: 0
                                }
                            },
                            tooltips: {
                                enabled: false
                            },
                            scales: {
                                yAxes: [{
                                    display: false
                                }],
                                xAxes: [{
                                    display: false
                                }]
                            }
                        }
                    });



                    // Grafico visitatori per regione

                    let resultDataLabelVisitorCountry = Analytics.getLabelDataVisitorCountry(result.visitors.grouped_by_country);

                    let ctx5 = document.getElementById('visitorsChartCountry');
                    this.visitorsChartCountry = new Chart(ctx5, {
                        // The type of chart we want to create
                        type: 'bar',

                        // The data for our dataset
                        data: {
                            labels: resultDataLabelVisitorCountry.label,
                            datasets: [{
                                label: '',
                                backgroundColor: 'rgba(79, 99, 196, 0.3)',
                                borderColor: 'rgb(78, 98, 196)',
                                borderWidth: '2',
                                lineTension: 0,
                                spanGaps: false,
                                data: resultDataLabelVisitorCountry.data
                            }]
                        },

                        // Configuration options go here
                        options: {
                            legend: {
                                display: false
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        display: false
                                    }
                                }]
                            }
                        }
                    });

                    // Grafico presenza per sito
                    let resultDataLabelVisitorProduct = Analytics.getLabelDataVisitorProduct(result.visitors.grouped_by_product);


                    let ctx6 = document.getElementById('visitorsChartProduct');
                    this.visitorsChartProduct = new Chart(ctx6, {
                        // The type of chart we want to create
                        type: 'horizontalBar',

                        // The data for our dataset
                        data: {
                            labels: resultDataLabelVisitorProduct.label,
                            datasets: [{
                                label: '',
                                backgroundColor: 'rgba(79, 99, 196, 0.3)',
                                borderColor: 'rgb(78, 98, 196)',
                                borderWidth: '2',
                                lineTension: 0,
                                spanGaps: false,
                                data: resultDataLabelVisitorProduct.data
                            }]
                        },

                        // Configuration options go here
                        options: {
                            legend: {
                                display: false
                            },
                            scales: {
                                yAxes: [{
                                    gridLines: {
                                        display: false
                                    }
                                }],
                                xAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }
                    });


                    // grafico ricavi mensili
                    let revenues_month_label = result.revenues.grouped_by_month.map(a => a.month);
                    let revenues_month_data = result.revenues.grouped_by_month.map(a => a.price_sum);
                    let revenuesDataResult = Analytics.getDataRevenuesMonth(revenues_month_label, revenues_month_data);
                    let ctx7 = document.getElementById('revenuesChartMonth');
                    this.revenuesChart = new Chart(ctx7, {
                        // The type of chart we want to create
                        type: 'line',

                        // The data for our dataset
                        data: {
                            labels: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
                            datasets: [{
                                label: '€',
                                backgroundColor: 'rgb(72, 184, 137, 0.3)',
                                borderColor: 'rgb(72, 184, 137)',
                                borderWidth: '3',
                                pointBackgroundColor: 'rgb(72, 184, 137)',
                                pointRadius: '5',
                                pointBorderWidth: '0',
                                pointHoverBorderWidth: '0',
                                pointHoverRadius: '5',
                                pointHoverBackgroundColor: 'rgb(72, 184, 137)',
                                pointHoverBorderColor: 'rgb(72, 184, 137)',
                                lineTension: 0,
                                data: revenuesDataResult
                            }]
                        },

                        // Configuration options go here
                        options: {
                            legend: {
                                display: false
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        display: false
                                    }
                                }]
                            }
                        }
                    });

                    //Grafico ricavi totali
                    let ctx8 = document.getElementById('revenuesTotalChart');
                    this.revenuesTotalChartTitle = "€" + Analytics.getNumber(result.revenues.total.price_final_sum);
                    this.revenuesTotalChart = new Chart(ctx8, {
                        // The type of chart we want to create
                        type: 'line',

                        // The data for our dataset
                        data: {
                            labels: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
                            datasets: [{
                                label: '€',
                                backgroundColor: 'rgb(72, 184, 137, 0.3)',
                                borderColor: 'rgb(72, 184, 137)',
                                borderWidth: '2',
                                lineTension: 0,
                                borderJoinStyle: 'round',
                                data: revenuesDataResult
                            }]
                        },

                        // Configuration options go here
                        options: {
                            legend: {
                                display: false
                            },
                            elements: {
                                point:{
                                    radius: 0
                                }
                            },
                            tooltips: {
                                enabled: false
                            },
                            scales: {
                                yAxes: [{
                                    display: false
                                }],
                                xAxes: [{
                                    display: false
                                }]
                            }
                        }
                    });

                    //Grafico ricavo per nazionalità
                    let resultDataLabelRevenuesCountry = Analytics.getLabelDataRevenuesCountry(result.revenues.grouped_by_country);

                    let ctx9 = document.getElementById('revenueChartCountry');
                    this.revenueChartCountry = new Chart(ctx9, {
                        // The type of chart we want to create
                        type: 'bar',

                        // The data for our dataset
                        data: {
                            labels: resultDataLabelRevenuesCountry.label,
                            datasets: [{
                                label: '€',
                                backgroundColor: 'rgb(72, 184, 137, 0.3)',
                                borderColor: 'rgb(72, 184, 137)',
                                borderWidth: '2',
                                lineTension: 0,
                                spanGaps: false,
                                data: resultDataLabelRevenuesCountry.data
                            }]
                        },

                        // Configuration options go here
                        options: {
                            legend: {
                                display: false
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        display: false
                                    }
                                }]
                            }
                        }
                    });

                    // Grafico ricavi per sito

                    let resultLabelDataRevenuesProduct = Analytics.getLabelDataRevenuesProduct(result.revenues.grouped_by_product);

                    let ctx10 = document.getElementById('revenuesChartProduct');
                    this.revenuesChartProduct = new Chart(ctx10, {
                        // The type of chart we want to create
                        type: 'horizontalBar',

                        // The data for our dataset
                        data: {
                            labels: resultLabelDataRevenuesProduct.label,
                            datasets: [{
                                label: '€',
                                backgroundColor: 'rgb(72, 184, 137, 0.3)',
                                borderColor: 'rgb(72, 184, 137)',
                                borderWidth: '2',
                                lineTension: 0,
                                spanGaps: false,
                                data: resultLabelDataRevenuesProduct.data
                            }]
                        },

                        // Configuration options go here
                        options: {
                            legend: {
                                display: false
                            },
                            scales: {
                                yAxes: [{
                                    gridLines: {
                                        display: false
                                    }
                                }],
                                xAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }
                    });

                  // grafico presenze giornaliere
                  const dailyVisitorsData = {
                    labels: [],
                    datasets: [{
                      label: 'Visitatori giornalieri',
                      backgroundColor: 'rgba(79, 99, 196, 0.3)',
                      borderColor: 'rgb(78, 98, 196)',
                      borderWidth: '1',
                      pointBackgroundColor: 'rgba(79, 99, 196)',
                      pointRadius: '2',
                      pointBorderWidth: '0',
                      pointHoverBorderWidth: '0',
                      pointHoverRadius: '2',
                      pointHoverBackgroundColor: 'rgba(79, 99, 196)',
                      pointHoverBorderColor: 'rgba(79, 99, 196)',
                      lineTension: 0,
                      data: []
                    }]
                  };

                  const dailyNotResidentVisitorsData = {
                    labels: [],
                    datasets: [{
                      label: 'Visitatori non residenti giornalieri',
                      backgroundColor: 'rgba(79, 99, 196, 0.3)',
                      borderColor: 'rgb(78, 98, 196)',
                      borderWidth: '1',
                      pointBackgroundColor: 'rgba(79, 99, 196)',
                      pointRadius: '2',
                      pointBorderWidth: '0',
                      pointHoverBorderWidth: '0',
                      pointHoverRadius: '2',
                      pointHoverBackgroundColor: 'rgba(79, 99, 196)',
                      pointHoverBorderColor: 'rgba(79, 99, 196)',
                      lineTension: 0,
                      data: []
                    }]
                  };

                  const dailyResidentVisitorsData = {
                    labels: [],
                    datasets: [{
                      label: 'Visitatori residenti giornalieri',
                      backgroundColor: 'rgba(79, 99, 196, 0.3)',
                      borderColor: 'rgb(78, 98, 196)',
                      borderWidth: '1',
                      pointBackgroundColor: 'rgba(79, 99, 196)',
                      pointRadius: '2',
                      pointBorderWidth: '0',
                      pointHoverBorderWidth: '0',
                      pointHoverRadius: '2',
                      pointHoverBackgroundColor: 'rgba(79, 99, 196)',
                      pointHoverBorderColor: 'rgba(79, 99, 196)',
                      lineTension: 0,
                      data: []
                    }]
                  };

                  const dailyRevenuesData = {
                    labels: [],
                    datasets: [{
                      label: 'Ricavi giornalieri',
                      backgroundColor: 'rgb(72, 184, 137, 0.3)',
                      borderColor: 'rgb(72, 184, 137)',
                      borderWidth: '1',
                      pointBackgroundColor: 'rgb(72, 184, 137)',
                      pointRadius: '2',
                      pointBorderWidth: '0',
                      pointHoverBorderWidth: '0',
                      pointHoverRadius: '2',
                      pointHoverBackgroundColor: 'rgb(72, 184, 137)',
                      pointHoverBorderColor: 'rgb(72, 184, 137)',
                      lineTension: 0,
                      data: []
                    }]
                  };

                  const startDate = new Date(`${this.currentYear}-01-01`);
                  const endDate = new Date(`${this.currentYear}-12-31`);
                  const currentDate = new Date(startDate);

                  var euroFormatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'EUR'
                  });

                  while (currentDate <= endDate) {
                    const matchingVisitorsData = result.visitors.grouped_by_day.find( entry => {
                          var date = new Date(entry.date)
                          return date.getDate() === currentDate.getDate() && date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear()

                        });

                    const matchingNotResidentVisitorsData = result.visitors.not_resident_grouped_by_day.find( entry => {
                      var date = new Date(entry.date)
                      return date.getDate() === currentDate.getDate() && date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear()

                    });

                    const matchingResidentVisitorsData = result.visitors.resident_grouped_by_day.find( entry => {
                      var date = new Date(entry.date)
                      return date.getDate() === currentDate.getDate() && date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear()

                    });

                    const matchingRevenuesData = result.revenues.grouped_by_day.find( entry => {
                      var date = new Date(entry.date)
                      return date.getDate() === currentDate.getDate() && date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear()

                    });

                    dailyVisitorsData.labels.push(currentDate.toLocaleDateString('it-IT', { month: 'short', day: 'numeric' }));
                    dailyRevenuesData.labels.push(currentDate.toLocaleDateString('it-IT', { month: 'short', day: 'numeric' }));
                    dailyNotResidentVisitorsData.labels.push(currentDate.toLocaleDateString('it-IT', { month: 'short', day: 'numeric' }));
                    dailyResidentVisitorsData.labels.push(currentDate.toLocaleDateString('it-IT', { month: 'short', day: 'numeric' }));

                    dailyVisitorsData.datasets[0].data.push(matchingVisitorsData ? matchingVisitorsData.people : 0);
                    dailyNotResidentVisitorsData.datasets[0].data.push(matchingNotResidentVisitorsData ? matchingNotResidentVisitorsData.people : 0);
                    dailyResidentVisitorsData.datasets[0].data.push(matchingResidentVisitorsData ? matchingResidentVisitorsData.people : 0);
                    dailyRevenuesData.datasets[0].data.push(matchingRevenuesData ? matchingRevenuesData.price_sum : 0);

                    currentDate.setDate(currentDate.getDate() + 1);
                  }

                  let ctx11 = document.getElementById('visitorsChartDaily');
                  this.visitorsChartDaily = new Chart(ctx11, {
                    type: 'line',
                    data: dailyVisitorsData,
                    options: {
                      tooltips: {
                        backgroundColor: "rgb(255,255,255)",
                        bodyFontColor: "#858796",
                        titleMarginBottom: 10,
                        titleFontColor: '#6e707e',
                        titleFontSize: 14,
                        borderColor: '#dddfeb',
                        borderWidth: 1,
                        xPadding: 15,
                        yPadding: 15,
                        displayColors: false,
                        intersect: false,
                        mode: 'index',
                        caretPadding: 10
                      },
                      scales: {
                        x: {
                          type: 'linear'
                        },
                        y: {
                          type: 'linear'
                        }
                      },
                      plugins: {
                        zoom: {
                          pan: {
                            // Boolean to enable panning
                            enabled: true,
                            mode: 'xy',
                            rangeMin: {
                              // Format of min zoom range depends on scale type
                              x: null,
                              y: 0
                            },
                            rangeMax: {
                              // Format of max zoom range depends on scale type
                              x: null,
                              y: null
                            },
                            // On category scale, factor of pan velocity
                            speed: 20,
                            threshold: 10,
                          },
                          zoom: {
                            enabled: true,
                            mode: 'x',
                            rangeMin: {
                              // Format of min zoom range depends on scale type
                              x: null,
                              y: 0
                            },
                            rangeMax: {
                              // Format of max zoom range depends on scale type
                              x: null,
                              y: null
                            },
                            speed: 0.1,
                            // Minimal zoom distance required before actually applying zoom
                            // On category scale, minimal zoom level before actually applying zoom
                            sensitivity: 0.1,
                          },
                        }
                      }
                    }
                  });

                  let ctx12 = document.getElementById('notResidentVisitorsChartDaily');
                  this.notResidentVisitorsChartDaily = new Chart(ctx12, {
                    type: 'line',
                    data: dailyNotResidentVisitorsData,
                    options: {
                      tooltips: {
                        backgroundColor: "rgb(255,255,255)",
                        bodyFontColor: "#858796",
                        titleMarginBottom: 10,
                        titleFontColor: '#6e707e',
                        titleFontSize: 14,
                        borderColor: '#dddfeb',
                        borderWidth: 1,
                        xPadding: 15,
                        yPadding: 15,
                        displayColors: false,
                        intersect: false,
                        mode: 'index',
                        caretPadding: 10
                      },
                      scales: {
                        x: {
                          type: 'linear'
                        },
                        y: {
                          type: 'linear'
                        }
                      },
                      plugins: {
                        zoom: {
                          pan: {
                            // Boolean to enable panning
                            enabled: true,
                            mode: 'xy',
                            rangeMin: {
                              // Format of min zoom range depends on scale type
                              x: null,
                              y: 0
                            },
                            rangeMax: {
                              // Format of max zoom range depends on scale type
                              x: null,
                              y: null
                            },
                            // On category scale, factor of pan velocity
                            speed: 20,
                            threshold: 10,
                          },
                          zoom: {
                            enabled: true,
                            mode: 'x',
                            rangeMin: {
                              // Format of min zoom range depends on scale type
                              x: null,
                              y: 0
                            },
                            rangeMax: {
                              // Format of max zoom range depends on scale type
                              x: null,
                              y: null
                            },
                            speed: 0.1,
                            // Minimal zoom distance required before actually applying zoom
                            // On category scale, minimal zoom level before actually applying zoom
                            sensitivity: 0.1,
                          },
                        }
                      }
                    }
                  });

                  let ctx13 = document.getElementById('residentVisitorsChartDaily');
                  this.residentVisitorsChartDaily = new Chart(ctx13, {
                    type: 'line',
                    data: dailyResidentVisitorsData,
                    options: {
                      tooltips: {
                        backgroundColor: "rgb(255,255,255)",
                        bodyFontColor: "#858796",
                        titleMarginBottom: 10,
                        titleFontColor: '#6e707e',
                        titleFontSize: 14,
                        borderColor: '#dddfeb',
                        borderWidth: 1,
                        xPadding: 15,
                        yPadding: 15,
                        displayColors: false,
                        intersect: false,
                        mode: 'index',
                        caretPadding: 10
                      },
                      scales: {
                        x: {
                          type: 'linear'
                        },
                        y: {
                          type: 'linear'
                        }
                      },
                      plugins: {
                        zoom: {
                          pan: {
                            // Boolean to enable panning
                            enabled: true,
                            mode: 'xy',
                            rangeMin: {
                              // Format of min zoom range depends on scale type
                              x: null,
                              y: 0
                            },
                            rangeMax: {
                              // Format of max zoom range depends on scale type
                              x: null,
                              y: null
                            },
                            // On category scale, factor of pan velocity
                            speed: 20,
                            threshold: 10,
                          },
                          zoom: {
                            enabled: true,
                            mode: 'x',
                            rangeMin: {
                              // Format of min zoom range depends on scale type
                              x: null,
                              y: 0
                            },
                            rangeMax: {
                              // Format of max zoom range depends on scale type
                              x: null,
                              y: null
                            },
                            speed: 0.1,
                            // Minimal zoom distance required before actually applying zoom
                            // On category scale, minimal zoom level before actually applying zoom
                            sensitivity: 0.1,
                          },
                        }
                      }
                    }
                  });

                  let ctx14 = document.getElementById('revenuesChartDaily');
                  this.revenuesChartDaily = new Chart(ctx14, {
                    type: 'line',
                    data: dailyRevenuesData,
                    options: {
                      tooltips: {
                        backgroundColor: "rgb(255,255,255)",
                        bodyFontColor: "#858796",
                        titleMarginBottom: 10,
                        titleFontColor: '#6e707e',
                        titleFontSize: 14,
                        borderColor: '#dddfeb',
                        borderWidth: 1,
                        xPadding: 15,
                        yPadding: 15,
                        displayColors: false,
                        intersect: false,
                        mode: 'index',
                        caretPadding: 10,
                        callbacks: {
                          label: function (tooltipItem, data) {
                            var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            return euroFormatter.format(value);
                          }
                        }
                      },
                      scales: {
                        x: {
                          type: 'linear'
                        },
                        y: {
                          type: 'linear'
                        }
                      },
                      plugins: {
                        zoom: {
                          pan: {
                            // Boolean to enable panning
                            enabled: true,
                            mode: 'xy',
                            rangeMin: {
                              // Format of min zoom range depends on scale type
                              x: null,
                              y: 0
                            },
                            rangeMax: {
                              // Format of max zoom range depends on scale type
                              x: null,
                              y: null
                            },
                            // On category scale, factor of pan velocity
                            speed: 20,
                            threshold: 10,
                          },
                          zoom: {
                            enabled: true,
                            mode: 'x',
                            rangeMin: {
                              // Format of min zoom range depends on scale type
                              x: null,
                              y: 0
                            },
                            rangeMax: {
                              // Format of max zoom range depends on scale type
                              x: null,
                              y: null
                            },
                            speed: 0.1,
                            // Minimal zoom distance required before actually applying zoom
                            // On category scale, minimal zoom level before actually applying zoom
                            sensitivity: 0.1,
                          },
                        }
                      }
                    }
                  });

                  Chart.register(zoomPlugin)

                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            }
        },
        computed: {
            todayDate: function () {
                return moment().format('DD/MM/YYYY')
            }
        }
    };
</script>

<style lang="css" src="../assets/paper.min.css"></style>
